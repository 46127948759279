import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import Curso from "../components/curso"
import Tree from "../images/cursos/Tree.webp"
import Flower from "../images/cursos/Flower.webp"

const Emociones = () => {
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

    return hasMounted ? (
        <Layout>
            <Curso 
                title='Cómo nuestras emociones moldean la percepción del mundo'
                phrase='Descripción detallada de nuestras percepciones y emociones.'
                time='5 Horas'
                class='4 Sesiones'
                students='3 - 6 Estudiantes'
                firstImg={Tree}
                secondImg={Flower}
                description='En este curso profundizaremos en cómo es el proceso de percepción según el planteamiento budista, y de qué manera nuestras respuestas emocionales moldean lo que pensamos, decimos y hacemos.'
                list={[' Revisión de ideas del curso de Fundamentos de la filosofía budista que serán fundamentales para este curso.', 'Cómo funciona nuestra conciencia: proceso de percepción sensorial.', 'Estructura de nuestras emociones: función, características, manifestación y causa próxima.']}
                duracion='4 sesiones de entre 60 y 75 min.'
                cupo='mínimo 3 personas, máximo 6 personas.'
                precio='$1,000 MXN.'
                top='15%'
                imgWidth='20%'
                right='5%'
                topMob='30%'
                rightMob='1%'
                imgWidthMob='25%'
            />
        </Layout>
    ) : null
}

export default Emociones